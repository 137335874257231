import Vue from 'vue';
import Router from 'vue-router';
import NorthernLightHunting from './views/tours/NorthernLightHunting.vue';
import IcelandicHorses from './views/blogs/IcelandicHorses.vue';
import SouthernIceland from './views/tours/SouthernIceland.vue';
import NorthernLights from './views/blogs/NorthernLights.vue';
import FourDaysWestToNorth from './views/tours/FourDaysWestToNorth.vue';
import FourDaysSouthernIceland from './views/tours/FourDaysSouthernIceland.vue';
import TwoDaysPuffinAndGlacier from './views/tours/TwoDaysPuffinAndGlacier.vue';
import SimCards from './views/blogs/SimCards.vue';
import TopTen from './views/blogs/TopTen.vue';
import Contact from './views/Contact.vue';
import About from './views/About.vue';
import Tours from './views/Tours.vue';
import Blogs from './views/Blogs.vue';
import Home from './views/Home.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	scrollBehavior (to, from, savedPosition) {
		return {
			x: 0,
			y: 0
		};
	},
	routes: [
		// About
		{
			path: '/about',
			name: 'aboutPlain',
			component: About,
			redirect: (to) => {
				return {
					name: 'about',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/about',
			name: 'about',
			component: About
		},
		// Contact
		{
			path: '/contact',
			name: 'contactPlain',
			component: Contact,
			redirect: (to) => {
				return {
					name: 'contact',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/contact',
			name: 'contact',
			component: Contact
		},
		// Tours
		{
			path: '/tours',
			name: 'toursPlain',
			component: Tours,
			redirect: (to) => {
				return {
					name: 'tours',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/tours',
			name: 'tours',
			component: Tours
		},
		// Blogs
		{
			path: '/blog',
			name: 'blogsPlain',
			component: Blogs,
			redirect: (to) => {
				return {
					name: 'blog',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/blog',
			name: 'blog',
			component: Blogs
		},
		// Blog One
		{
			path: '/blog/icelandic_horses',
			name: 'blogsOnePlain',
			component: IcelandicHorses,
			redirect: (to) => {
				return {
					name: 'blogsOne',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/blog/icelandic_horses',
			name: 'blogsOne',
			component: IcelandicHorses
		},
		// Blog Two
		{
			path: '/blog/top_10_reasons_to_visit_iceland',
			name: 'blogsTwoPlain',
			component: TopTen,
			redirect: (to) => {
				return {
					name: 'blogsTwo',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/blog/top_10_reasons_to_visit_iceland',
			name: 'blogsTwo',
			component: TopTen
		},
		// Blog Three
		{
			path: '/blog/icelandic_sim_cards',
			name: 'blogsThreePlain',
			component: SimCards,
			redirect: (to) => {
				return {
					name: 'blogsThree',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/blog/icelandic_sim_cards',
			name: 'blogsThree',
			component: SimCards
		},
		// Blog Four
		{
			path: '/blog/about_northern_lights',
			name: 'blogsFourPlain',
			component: NorthernLights,
			redirect: (to) => {
				return {
					name: 'blogsFour',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/blog/about_northern_lights',
			name: 'blogsFour',
			component: NorthernLights
		},
		// Tour One
		{
			path: '/tours/northern_light_hunting',
			name: 'toursOnePlain',
			component: NorthernLightHunting,
			redirect: (to) => {
				return {
					name: 'toursOne',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/tours/northern_light_hunting',
			name: 'toursOne',
			component: NorthernLightHunting,
			meta: {
				reload: true
			}
		},
		// Tour Two
		{
			path: '/tours/southern_iceland',
			name: 'toursTwoPlain',
			component: SouthernIceland,
			redirect: (to) => {
				return {
					name: 'toursTwo',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/tours/southern_iceland',
			name: 'toursTwo',
			component: SouthernIceland,
			meta: {
				reload: true
			}
		},
		// Tour Three
		{
			path: '/tours/four-days-west-to-north',
			name: 'toursThreePlain',
			component: FourDaysWestToNorth,
			redirect: (to) => {
				return {
					name: 'toursThree',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/tours/four-days-west-to-north',
			name: 'toursThree',
			component: FourDaysWestToNorth,
			meta: {
				reload: true
			}
		},
		// Tour Four
		{
			path: '/tours/4-days-southern-iceland',
			name: 'toursFourPlain',
			component: FourDaysSouthernIceland,
			redirect: (to) => {
				return {
					name: 'toursFour',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/tours/4-days-southern-iceland',
			name: 'toursFour',
			component: FourDaysSouthernIceland,
			meta: {
				reload: true
			}
		},
		// Tour Five
		{
			path: '/tours/2-days-puffin-and-glacier',
			name: 'toursFivePlain',
			component: TwoDaysPuffinAndGlacier,
			redirect: (to) => {
				return {
					name: 'toursFive',
					params: {
						language: 'cn'
					}
				};
			}
		},
		{
			path: '/:language/tours/2-days-puffin-and-glacier',
			name: 'toursFive',
			component: TwoDaysPuffinAndGlacier,
			meta: {
				reload: true
			}
		},
		// Home
		{
			path: '/',
			name: 'homePlain',
			component: Home
			/*
			redirect: (to) => {
				return {
					name: 'home',
					params: {
						language: 'cn'
					}
				};
			}
			*/
		},
		{
			path: '/:language',
			name: 'home',
			component: Home
		}
	]
});
