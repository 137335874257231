import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		seo: {
			keywords: {
				english: 'Northern Lights, Iceland Travels and Tours, Iceland Coach Charter',
				chinese: '北极光, 冰岛旅游'
			},
			description: {
				english: 'Experience adventures in Iceland with New Horizons. See the northern lights, The golden circle and more with our expert guides.',
				chinese: '［5年百分百成功追寻北极光记录］冰岛新视野旅行社提供专业北极光旅游团，夏天午夜太阳冰岛旅游，探寻蓝冰洞话动，各种冰岛一日游活动，私人包车配司兼导服务，安排住宿服务。'
			}
		},
		seoContact: {
			description: {
				english: 'Contact the experts in Icelandic tours. Let the experts in Iceland take care of your needs.',
				chinese: '［5年百分百成功追寻北极光记录］冰岛新视野旅行社提供专业北极光旅游团，夏天午夜太阳冰岛旅游，探寻蓝冰洞话动，各种冰岛一日游活动，私人包车配司兼导服务，安排住宿服务。'
			}
		},
		seoAbout: {
			description: {
				english: 'Let the experts in Iceland take care of your needs. Adventures await in the land of the Northern Lights.',
				chinese: '旅行，体验和景点。 一站式服务'
			}
		},
		seoBlog: {
			description: {
				english: 'Keep up to date with the newest info about Iceland, The golden circle, Northern lights and more.',
				chinese: '［5年百分百成功追寻北极光记录］冰岛新视野旅行社提供专业北极光旅游团，夏天午夜太阳冰岛旅游，探寻蓝冰洞话动，各种冰岛一日游活动，私人包车配司兼导服务，安排住宿服务。'
			}
		},
		seoTours: {
			description: {
				english: 'New Horizons has a 100% Aurora Sighting Record for the past 5 years. Experience the Northern lights, the golden circle and the north west with expert guides.',
				chinese: '搜索冰岛附近的数百个地点，找到您完美的度假景点'
			}
		},
		language: 'chinese',
		currency: null,
		blogGrid: {
			header: {
				english: 'TRAVEL BLOGS & NEWS',
				chinese: '旅游博客和新闻'
			},
			read: {
				english: 'Read',
				chinese: '读'
			}
		},
		northernLight: {
			seo: {
				english: 'New Horizons has a 100% Aurora Sighting Record for the past 5 years.  Keep up to date with all the new northern lights news.',
				chinese: '冰岛之北极光篇'
			},
			title: {
				english: 'ABOUT NORTHERN LIGHT',
				chinese: '冰岛之北极光篇'
			},
			para1: {
				english: 'Iceland: Northern Light',
				chinese: '冰岛之北极光篇'
			},
			para2: {
				english: 'Catching a glimpse of the Northern Lights has been topped a bucket list of things must-see destination for most of the people.',
				chinese: '想必很多人的人生清单上， 都列有北极光这一项，小编也不例外。在北欧，流传着一种说法：看到了北极光的人，将会一生幸福。但，什么是北极光？什么时候是观看北极光的最佳时期? 在哪里可以看到及拍到最美的北极光呢？今天，让小编为大家说说北极光的那些事。'
			},
			para3: {
				english: 'The Northern Lights, also called Aurora Borealis, are one of the most spectacular shows on this Earth and can frequently be seen in Iceland from September through April on cold, clear and crisp nights. It is named after the Roman goddess of dawn, Aurora, and Greek god of the north wind, Boreas. Iceland is located in the middle of the Aurora belt, so it’s an ideal destination for viewing and photographing aurora borealis.',
				chinese: '北极光 或 欧若拉（aurora）,是以罗马黎明女神命名。极光，是在高纬度的天空中，由太阳带电粒子流进入地球大气、与气体粒子碰撞时而产生。北极光，是天空的‘万花筒’，是一场令人心醉神迷的光之秀。极光有绿色、粉红色、蓝色、白色和紫罗兰色， 以绿色较为普遍。要观看极光，须有耐心、毅力及一点小幸运。'
			},
			para4: {
				english: 'What causes this spectacular phenomenon, so characteristic of the Arctic skies? Well, it’s created by solar winds interacting with charged particles in the earth’s magnetic field, the molecules glow and appear as streaks of green, red, yellow, white, purple and pink dancing across the skies. The lights come at night when the sky is clear and dark. The colours are utterly brilliant and pure. The vibrant shades are in perpetual motion, dancing, flowing changing shades. It is the best show on earth.',
				chinese: '北极光是全年都存在的自然现象， 但最佳观赏极光的‘季节’是在天较黑的月份，一般从9月到4月，都可能观赏到。冰岛各地均可见到极光， 但小编建议大家到城镇之外的地区观赏。如此一来，不仅可以减低光污染，又可以用大自然景物（湖泊，山丘，冰河湖，瀑布）为背景，为极光照片增添另一番视觉效果。'
			},
			para5: {
				english: 'The whole Iceland offers good chances to see the Northern Lights. However, I will advise you to go somewhere free of light pollution, far from city lights.  A good foreground generally improves a photograph of the auroras, gives scale and makes it more interesting. There are plenty of good foregrounds anywhere in Iceland: waterfalls, mountains, fjords, lakes and glaciers. Iceland’s unique backdrop makes it a favourite place for photographers to capture the Northern Lights.',
				chinese: '在天寒地冻、神秘寂静的冰岛，亲眼目睹绚丽的极光在天空上自由舞动，那种震撼可谓是一生中难得的体验。小编在抵达冰岛的第一个晚上，就见到了极光，就此被炫目的色彩深深吸引，人回来了，心还挂在那儿。'
			},
			para6: {
				english: 'One of the most magical, which I have had the good fortune to experience several times, is standing beneath the aurora borealis. It’s such a great experience that you will not forget in your lifetime. Words are never adequate to describe what it looks like, and how I feel in response. I would say, the lure of Aurora Borealis, is timeless!',
				chinese: ''
			}
		},
		simCard: {
			seo: {
				english: 'When you travel to Iceland you can buy a local sim card for your convenient. You can check the weather and the road conditions anytime.',
				chinese: '来冰岛旅游，到达机场后，买一张当地电话卡会带来很多方便。尤其在冰岛自驾环岛时，拥有信号畅通的手机卡，随时上网查看路况和天气，非常实用。'
			},
			title: {
				english: 'ICELAND SIM CARD',
				chinese: '冰岛电话卡和WIFI指南'
			},
			para1: {
				english: 'When travel to Iceland, you can buy a local sim card for your convenient especially self drive friends. You can check the weather and the road conditions anytime.',
				chinese: '来冰岛旅游，到达机场后，买一张当地电话卡会带来很多方便。尤其在冰岛自驾环岛时，拥有信号畅通的手机卡，随时上网查看路况和天气，非常实用。'
			},
			subTitle1: {
				english: 'Where to buy the local sim card?',
				chinese: '在哪儿怎么买电话卡？'
			},
			para2: {
				english: 'It\'s simple,just buy it at Iceland\'s airport or 10-11 store by using credit card or cash.',
				chinese: '购买冰岛的预付费电话卡，非常方便，不需要提供任何证件，可以刷信用卡和现金。'
			},
			para2_1: {
				english: '',
				chinese: '在冰岛航空和WOW航空的航班上。'
			},
			para2_2: {
				english: '',
				chinese: '冰岛的机场'
			},
			para2_3: {
				english: '',
				chinese: '冰岛加油站以及10-11超市分店。'
			},
			subTitle2: {
				english: 'Rent wifi router or buy Icelandic sim card?',
				chinese: '租WIFI还是买冰岛电话卡？'
			},
			para3: {
				english: 'Compared to wifi, Iceland mobile network is more faster and wide coverage including the black sand beach and glacier areas,the signal may be weak but can call anyway.',
				chinese: '相比租WIFI，冰岛电话的信号普遍会更强，而且更实用。冰岛手机信号覆盖广泛，主要城镇、以及沿一号公路都能收到信号。即使部分路段为黑沙荒漠、或冰川附近无人区，信号可能相对较弱，但仍然能拨打电话。'
			},
			subTitle3: {
				english: 'Do we need local sim card if the car come with GPS?',
				chinese: '冰岛自驾，有GPS还需要电话上网卡吗？'
			},
			para4: {
				english: 'You are given GPS when buy a self drive package. You need to upgrade to get GPS if rent car only. However suggested to buy a sim card as the weather in Iceland is unpredictable. If you are stuck somewhere because of a snowstorm you able to let your hotel know that you are running late or get recommendations where you can go if the road is going to be closed.',
				chinese: '购买自驾套餐，都会赠送GPS；如果只是租车，则需要自行升级租GPS。然而，无论是否有导航，都建议能有信号畅通的电话卡，由于冰岛的天气多变，尤其在冬季，实时查看路况对于安全出行非常关键，这时需要冰岛本地电话卡，上网查看路况，或拨打电话号码1777进行路况的人工查询。'
			},
			subTitle4: {
				english: 'What are the local mobile network operators in Iceland?',
				chinese: '冰岛有哪些本地的电话公司？'
			},
			para5: {
				english: 'There are four major mobile network operators: Siminn, Nova, Vodafone and Tal.',
				chinese: '有4家主要的电话供应商，分别是Siminn、 Nova、Vodafone，还有Tal。'
			},
			para6: {
				english: 'Siminn is the market leader with the best coverage and it is certified the fastest mobile network in Iceland at slightly higher rates.',
				chinese: 'Siminn 冰岛语中是“电话”的意思，这是冰岛最老的电信运营商，也是在冰岛用户最多的本地公司。Siminn 的信号最强。'
			},
			para7: {
				english: 'Nova is a new mobile network and popular among the youngsters in Iceland. Nova the cheapest and good when you stay mostly in Reykjavik.',
				chinese: 'Nova 虽然是冰岛较为新的电信公司，但在冰岛的年轻人之中非常受欢迎，主要原因是价格优惠，并且Nova用户之间打电话和发短信都是免费的。在郊外信号比较弱。'
			},
			para8: {
				english: 'Vodafone is the biggest competitor of Siminn. It\'s coverage quite good,even at some place better than Sinminn.',
				chinese: 'Vodafone 4家电话公司中，只有Vodafone是外国公司，来自英国。'
			},
			para9: {
				english: 'Tal has less users and primarily for Icelandic family services and are not practical for travelers.',
				chinese: 'Tal 用户较少，主要针对冰岛的家庭服务，对于旅行者不太实用。'
			},
			subTitle5: {
				english: 'How much for the Icelandic sim cards?',
				chinese: '冰岛买手机卡多少钱？'
			},
			para10: {
				english: 'Siminn offers 2 prepaid plans:',
				chinese: 'Siminn 对于旅行者有两种SIM卡，一种是2900冰岛克朗，含10GB上网流量；另一种是2900冰岛克朗，含50分钟通话，50条短信和5 GB上网。'
			},
			para11: {
				english: '2900 ISK for 10GB data',
				chinese: 'Nova 1490冰岛克朗，含1000冰岛克朗话费，1GB上网流量，100分钟通话。'
			},
			para12: {
				english: '2900 ISK for 5GB data, 50 min talk, 50 texts',
				chinese: 'Vodafone 2490冰岛克朗，含5GB上网流量，通话100分钟'
			},
			para13: {
				english: 'Nova offers 1490 ISK for 1000 ISK call, 1GB data,100 min talk',
				chinese: ''
			},
			para14: {
				english: 'Vodafone offers 2490 ISK  for 5GB data,100 min talk',
				chinese: ''
			}
		},
		topTen: {
			title: {
				english: 'TOP 10 REASONS TO VISIT ICELAND',
				chinese: '十个你会爱上冰岛的理由'
			},
			para1: {
				english: 'Iceland is a volcanic island located in the North Atlantic Ocean, 300km east of Greenland and 900km west of Norway. The main island lies south of the Arctic Circle. Despite its high latitude and proximity to the Arctic Circle, Iceland has a moderate climate because it’s warmed by the Gulf Stream. The temperature in Iceland ranging from -3 to 14 degree Celcius. However, the weather in Iceland is very changeable, sometimes one may occasionally experience the four seasons over a day. (Please stay tuned for our next article: The Weather and Climate of Iceland). Since Iceland has an area of 39,770 square miles, and the population is roughly 320000, making it the most sparsely populated nation in Europe. Reykjavik is the capital city of Iceland, which is also the biggest city in this country.',
				chinese: '冰岛：冰与火之地（简介篇）'
			},
			para2: {
				english: 'Iceland is a country of sharp contrasts. It is a landscape forged by fire and ice. Steaming lava fields and hot pools reflect its volcanic nature, whilst massive glaciers continue to sculpt the mountains and valleys, leaving the thundering waterfalls and plunging fjords. It shows a force of nature, both beautiful and brutal. Iceland’s nature remains mostly unspoilt as the island is scarcely populated. You can have the opportunity to see northern lights (also known as aurora borealis) lit up the skies across the dark winter. During summer, you will be witnessing one of the nature’s most magnificent shows on Earth- the setting of the midnight sun. Although Iceland might be a remote nation, it also happens to be one of the most picturesque. Millions of photographers and travellers were attracted by its breathtaking Nordic landscapes and subsequently embrace it with their bodies, souls and definitely, cameras.',
				chinese: '冰岛， 北纬66度的孤独星球， 一个美到破产的国家。冰岛位于北大西洋与北冰洋交界处， 是北大西洋中的一个岛国。冰岛的名字以及那靠近北极圈的高纬度， 常让人联想到爱斯基摩人，冰屋 那些大雪纷飞，寒风刺骨的景象。但由于暖流的作用，冰岛的实际气候比人们想象中的要温和的多。气温介于-3度至14度 之间。（请关注：冰岛气候篇）它是欧洲人口密度最小的国家， 人口约为32万。 首都是雷克雅未克， 也是冰岛最大的城市。'
			},
			para3: {
				english: 'If you are not yet convinced that Iceland must be your next destinations, here are some reasons why you should go to Iceland on your next vacation.',
				chinese: '它，是一个名副其实的冰与火之地，冰原上火山星罗棋布，火山灰点缀着雪白的冰山， 火山岩风化消蚀成就了黑沙滩，黑沙滩上闪烁着钻石般光芒的冰石。夏夜有阳光，冬夜有绚丽的极光。这里的空气无污染，这里的山脉如画，这里的冰川壮观，这里的瀑布千姿百态，这里的湖水蔚蓝， 处处都是让你叹为观止的景色'
			},
			subTitle1: {
				english: 'Top 10 reasons to visit Iceland:',
				chinese: '让小编为大家列出十个你会爱上冰岛的理由：'
			},
			bullet1: {
				english: 'The Magical Northern Light/ Aurora Borealis',
				chinese: '神秘的极光'
			},
			bullet2: {
				english: 'The Midnight Sun',
				chinese: '午夜阳光'
			},
			bullet3: {
				english: 'Blue Lagoon',
				chinese: '蓝湖温泉'
			},
			bullet4: {
				english: 'Waterfalls',
				chinese: '腾空而下的瀑布'
			},
			bullet5: {
				english: 'Glacier Lagoon',
				chinese: '千年冰河湖'
			},
			bullet6: {
				english: 'Ice caving',
				chinese: '蓝冰洞'
			},
			bullet7: {
				english: 'Glacier Hiking',
				chinese: '万年冰川'
			},
			bullet8: {
				english: 'Feisty Volcanoes',
				chinese: '冰岛火山'
			},
			bullet9: {
				english: 'Spurty Geysers',
				chinese: '间歇泉'
			},
			bullet10: {
				english: 'Fresh, contemporary cuisine',
				chinese: '冰岛美食'
			}
		},
		icelandicHorses: {
			seo: {
				english: 'Travel through the golden circle but don’t miss the chance to meet the Icelandic Horse.',
				chinese: '你是否还在犹豫着该不该去冰岛旅行？冰岛，有着‘冰与火的国度’之称，自然风光独特，极光之乡，以壮美瀑布而闻名。它，是摄影师必访的取景之地，也是旅游爱好者的天堂。在这神秘，纯净，寒冷 和与世隔绝的国度里，驰骋着一匹匹的神兽：冰岛马。'
			},
			title: {
				english: 'ICELANDIC HORSES',
				chinese: '冰岛马'
			},
			para1: {
				english: 'Are you thinking about going to Iceland? Iceland is a magical place with natural wonders, especially the northern lights, volcanoes, glaciers and amazing waterfalls(fjords). It’s the photographers must visit country and every traveler’s travel bucket list.  Other than those natural wonders mentioned above, you should never miss the chance to meet the prettiest horse in the world—The Icelandic Horse.',
				chinese: '你是否还在犹豫着该不该去冰岛旅行？冰岛，有着‘冰与火的国度’之称，自然风光独特，极光之乡，以壮美瀑布而闻名。它，是摄影师必访的取景之地，也是旅游爱好者的天堂。在这神秘，纯净，寒冷 和与世隔绝的国度里，驰骋着一匹匹的神兽：冰岛马。'
			},
			para2: {
				english: 'The Icelandic horse is a unique breed of smallish horses that came to Iceland with the first settlers from Norway in the 10th century. Icelandic horses weigh between 330 and 380 Kg. The horses are small, with average 52-56 inches standing height. They often considered as pony size. They come in many different coat colours, including chestnut, dun, bay, black, gray, palomino, pinto and roan. Some types of Icelandic horses’ coats could even changes colour by season.',
				chinese: '冰岛马是血统最纯正的马种，约在一千多年前由北欧挪威人引入到冰岛。冰岛马身形矮小，腿脚个短，身高52-56寸， 体重只有大约330至380公斤。其矮小的身材应该归类为矮种马，但可能因为冰岛文中没有 ‘pony’这个词，所以它都被登记为Icelandic Horse。它们的毛色多样化，包括栗色，黑色，棗红色，白色，棕灰色，帕洛米诺色等等。它们的有些甚至可以根据季节改变颜色。'
			},
			para3: {
				english: 'Icelandic horse is a ‘five-gaited’ breed. As well as the typical gaits of walk, trot and gallop, it displays two addition gaits namely tolt and flying pace (skeio). Tolt is well known for its explosive acceleration whilst flying pace is used in pacing race. It is capable of performing various tasks very well. It’s used for farm work despite increasing mechanization in the country. It’s also widely used for showing, racing, recreation and sheepherding.',
				chinese: '冰岛马有五种步伐，除了一般的慢步，快步及跑步外， 也有额外的两种步态。第一种是Tolt， 超群的加速和横向步伐；另一种skeio，即‘飞行步伐’，或称为快速短跑冲刺。它现代的用途是骑乘，竞赛及放牧羊群。'
			},
			para4: {
				english: 'The Icelandic horse has a very individual character. It is patient, adaptable, lovely and spirited. The Icelandic horse has always popular internationally for being friendly and easy-going. The love being around with people, sometimes they are called ‘therapy horses’.',
				chinese: '去冰岛旅行时，骑行冰岛马也是一个不错的选择。在火山脚下骑马漫步，这种奇妙旅程，是人生难得的体验。冰岛马天性友善，驯服，热情 及容易控制。如果你体验骑乘冰岛马，你也可以和热情友善的它一起拍照。它对人类不但不惧怕，反而会显得非常亲切友好。一旦你靠近马场的围栏，他们便纷纷聚集过来，对着镜头摆出帅气，忧郁而深邃的表情。幸运时，还可以拍到冰岛马微笑，拥抱的温馨画面。以冰岛延绵山脉为背景，帅气，幸福微笑的小马为配角，以你为主角的照片，绝对值得珍藏在你的相机里。'
			},
			para5: {
				english: 'Horse riding is a unique experience that you should not miss. It’s a great way to explore unspoiled nature, panoramic and stunning Icelandic landscapes. This fascinating horseback riding tour through the lava field or geothermal wonder experience will be on your best memories in Iceland.',
				chinese: '当你开车穿越公路，经过马场或草原时，经常会与冰岛马不期而遇。 这时别错过与它亲近的机会。下车去和它‘打招呼吧’，递给它一把干草，轻拍它的身体，触摸它的脸颊。凡爱马之人必为它为之疯狂，一见钟情。那些不喜欢马匹的旅客，看过了冰岛马，也将从此对它彻底的改观。'
			},
			para6: {
				english: 'If you are a horse lover, don’t ever miss the chance to say hello to the Icelandic horses while visiting Iceland. If you don’t like horses, this the chance for you to fall in love with it. They are just amazing. Your Ring Road trip wouldn’t be complete without petting an Icelandic horse!',
				chinese: '别忘了，在这纯净的国度里，住着纯净的人们，有着纯净的幸福之光，还有着这样的一群纯净的冰岛马！'
			}
		},
		blogs: [
			{
				link: 'blogsOne',
				image: '/blogs/1.jpg',
				title: {
					english: 'Icelandic Horses',
					chinese: '冰岛马'
				},
				description: {
					english: 'How much do you know about Icelandic Horses? Do you know that they are the world best species?',
					chinese: '你对冰岛马知多少?你知道他们是世界上最好的品种吗?'
				}
			},
			{
				link: 'blogsTwo',
				image: '/blogs/2.jpg',
				title: {
					english: 'Top 10 reasons to visit Iceland',
					chinese: '十个你会爱上冰岛的理由'
				},
				description: {
					english: 'There are 10 reasons that making many people visiting to Iceland.',
					chinese: '为什么有这样多人喜欢来冰岛? 看下去你就知道。'
				}
			},
			{
				link: 'blogsThree',
				image: '/blogs/3.jpg',
				title: {
					english: 'Iceland Sim Card',
					chinese: '冰岛电话卡和WIFI指南'
				},
				description: {
					english: 'This information is important for you to stay connected while traveling to Iceland.',
					chinese: '如何在冰岛保持无限连线。'
				}
			},
			{
				link: 'blogsFour',
				image: '/blogs/4.jpg',
				title: {
					english: 'About Northern Light',
					chinese: '冰岛之北极光篇'
				},
				description: {
					english: 'To understand more about Northern Light.',
					chinese: '多多了解北极光'
				}
			}
		],
		contact: {
			title: {
				english: 'CONTACT US',
				chinese: '联系我们'
			},
			firstName: {
				english: 'First Name',
				chinese: '名字'
			},
			lastName: {
				english: 'Last Name',
				chinese: '姓'
			},
			email: {
				english: 'Email',
				chinese: '电子邮件'
			},
			phone: {
				english: 'Phone',
				chinese: '电话'
			},
			message: {
				english: 'Message',
				chinese: '信息'
			},
			yourMessage: {
				english: 'Your Message',
				chinese: '你的信息'
			},
			send: {
				english: 'SEND',
				chinese: '发送'
			}
		},
		tours: {
			title: {
				english: 'All our tours',
				chinese: '我们所有的旅程'
			},
			subTitle: {
				english: 'Browse through our selection of tailored tours around Iceland',
				chinese: '搜索冰岛附近的数百个地点，找到您完美的度假景点'
			}
		},
		about: {
			main: {
				title: {
					english: 'WE ARE NEW HORIZONS',
					chinese: '我们是 NEW HORIZONS'
				},
				subTitleOne: {
					english: 'ADDRESS',
					chinese: '地址'
				},
				subTitleTwo: {
					english: 'PHONE',
					chinese: '电话'
				},
				paraOne: {
					english: 'We are a traveling agency in Iceland and we focus on introducing our customers to new sights, new experiences, new places. Our aim is to show you Iceland in unique way and we commit ourselves to bring you to your new horizons by taking you to places you have only dreamed of.',
					chinese: '我们是冰岛的旅行社，我们专注于向客户介绍新景点，新体验，新风格。 我们的目标是以独特的方式向您呈现冰岛，并致力于通过带您到达梦想中的地方，为您带来新的视野。'
				},
				paraTwo: {
					english: 'Hafnargata 90, 230 Reykjanesbær',
					chinese: 'Hafnargata 90, 230 Reykjanesbær.'
				},
				paraThree: {
					english: '+354 857 0646',
					chinese: '+354 857 0646'
				},
				viewTrips: {
					english: 'BOOK NOW',
					chinese: '現在預訂'
				},
				subTitleThree: {
					english: 'OUR PERMISSION',
					chinese: '我们的许可'
				},
				paraFour: {
					english: 'We have authorization to work as a travel agency in Iceland.',
					chinese: '我们有权在冰岛作为旅行社工作。'
				},
				subTitleFour: {
					english: 'OUR PROMISE',
					chinese: '我们的承诺'
				},
				paraFive: {
					english: 'We promise you to give you the best service we are capable of.',
					chinese: '我们承诺给你最好的服务，我们有能力。'
				},
				subTitleFive: {
					english: 'OUR TOURS',
					chinese: '我们的旅游'
				},
				paraSix: {
					english: 'We aim to be amongst the best tours in Iceland.',
					chinese: '我们的目标是成为冰岛最好的旅游之一。'
				},
				subTitleSix: {
					english: 'HAPPY TRAVELLERS',
					chinese: '快乐的旅客'
				},
				paraSeven: {
					english: 'Your happiness is our job, and we aim to leave all our travellers smiling and happy.',
					chinese: 'Y我们的幸福是我们的工作，我们的目标是让我们所有的旅客微笑和快乐。'
				}
			},
			title: {
				english: 'ABOUT US',
				chinese: '关于我们'
			},
			subTitle: {
				english: 'Tours, experiences, and places. All in one service',
				chinese: '旅行，体验和景点。 一站式服务'
			}
		},
		horizon: {
			header: {
				english: 'BRINGING THE HORIZON',
				chinese: '带来地平线'
			},
			para: {
				english: 'We are a traveling agency in Iceland and we focus on introducing our customers to new sights, new experiences, new places. Our aim is to show you Iceland in unique way and we commit ourselves to bring you to your new horizons by taking you to places you have only dreamed of.',
				chinese: '我们是冰岛的旅行社，我们专注于向客户介绍新景点，新景点，新景点。 我们的目标是以独特的方式向您呈现冰岛，并致力于通过带您到达梦想中的地方，为您带来新的视野。'
			},
			more: {
				english: 'READ MORE ABOUT US',
				chinese: '阅读更多关于我们'
			}
		},
		header: {
			title: {
				english: 'TRAVEL TO NEW HORIZONS',
				chinese: '旅行到新的视野'
			},
			subTitle: {
				english: 'Browse through our selection of tailored tours around Iceland',
				chinese: '搜索冰岛附近的数百个地点，找到您完美的度假景点'
			},
			viewAll: {
				english: 'FIND YOUR TOUR',
				chinese: '找到你的行程'
			}
		},
		navigation: {
			privacy: {
				english: 'Privacy Policy',
				chinese: '隐私政策'
			},
			terms: {
				english: 'Terms of Use',
				chinese: '使用条款'
			},
			cookies: {
				english: 'Cookies Policy',
				chinese: 'Cookie政策'
			},
			email: {
				english: 'Your Email',
				chinese: '你的邮件'
			},
			subscribe: {
				english: 'SUBSCRIBE',
				chinese: '订阅'
			},
			touch: {
				english: 'STAY IN TOUCH AND GET THE LATEST NEWS',
				chinese: '保持联系并获得最新消息'
			},
			company: {
				english: 'COMPANY',
				chinese: '公司'
			},
			useful: {
				english: 'USEFUl',
				chinese: '有用'
			},
			home: {
				english: 'Home',
				chinese: '主页'
			},
			tourist: {
				english: 'Tourist Board',
				chinese: '旅游局'
			},
			tour: {
				english: 'Tours',
				chinese: '旅游行程'
			},
			about: {
				english: 'About Us',
				chinese: '关于我们'
			},
			blog: {
				english: 'Travel Blogs',
				chinese: '旅行博客'
			},
			contact: {
				english: 'Contact Us',
				chinese: '联系我们'
			}
		},
		grid: {
			header: {
				english: 'OUR ADVENTURES',
				chinese: '我们的旅程'
			},
			viewAll: {
				english: 'VIEW ALL TOURS',
				chinese: '阅读所有行程'
			},
			viewSingle: {
				english: 'TAKE A LOOK',
				chinese: '看一看'
			},
			priceFrom: {
				english: 'PRICE FROM',
				chinese: '价格从'
			}
		},
		bookNowBottom: {
			buttonMessage: {
				english: 'Book this tour',
				chinese: '订购'
			},
			widgetScriptSrc: {
				english: 'https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=14c1969c-2c42-4141-bd76-863d8ca988d9',
				chinese: 'https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=9a1901bb-b1dd-41e2-9f8e-2403ae84e814'
			}
		},
		// HERE
		northernLightHunting: {
			seo: {
				english: 'Aurora Hunting by Mercedes Benz Sprinter (Small group)',
				chinese: '豪华奔驰斯宾特-极光团 '
			},
			title: {
				english: 'Aurora Hunting by Mercedes Benz Sprinter (Small group)',
				chinese: '豪华奔驰斯宾特-极光团 '
			},
			subTitle1: {
				english: 'Tour information:',
				chinese: '行程信息：'
			},
			bullet1: {
				english: 'Available: Aug- Apr',
				chinese: '运营期间：8月至4月'
			},
			bullet2: {
				english: 'Duration: 4-6 hours.',
				chinese: '所需时间: 4-6 小时'
			},
			bullet3: {
				english: 'Activities: Aurora Hunting',
				chinese: '项目：寻猎北极光'
			},
			subTitle2: {
				english: 'Pick up information:',
				chinese: '酒店接客须知：'
			},
			para1: {
				english: 'Please be at your pickup location in time for your departure. Please note that it can take up to 30 minutes for us to reach your pick up point.',
				chinese: '注: 接团过程大约持续30分钟，请准时在出发点等候，并耐心等待。 如错过了接待时间， 我们将无法返程重新接送或退款。'
			},
			subTitle3: {
				english: 'Guide Languages:',
				chinese: '导游使用的语言:'
			},
			bullet4: {
				english: 'English',
				chinese: '英语'
			},
			bullet5: {
				english: 'Mandarin',
				chinese: '华语'
			},
			bullet6: {
				english: 'Thailand (on request)',
				chinese: '泰语（根据要求）'
			},
			bullet7: {
				english: 'Malay or Indo (on request) ',
				chinese: '马来语或印尼语（根据要求）'
			},
			subTitle4: {
				english: 'Description:',
				chinese: '行程说明：'
			},
			para2: {
				english: 'Aurora is sometimes referred to as ‘polar light’. It is predominantly seen in the regions of high altitudes like the Arctic and Antarctic. An aurora is caused by the streams of electrified particles (which are emitted by the sun) trapped in the magnetic field of the earth.',
				chinese: '极光是地球周围的一种大规模放电的过程。来自太阳的带电粒子到达地球附近，地球磁场迫使其中一部分沿着磁场线集中到南北两极。当他们进入极地的高层大气（>80km）时，与大气中的原子和分子碰撞并激发，能量释放产生的光芒形成围绕着磁极的大圆圈，即极光。极光区的纬度为3至6度。它位于距离地磁极10到20度之间。在北半球观察到的极光称北极光，而冰岛由于整个国家都在极光带上，也是北半球受欢迎的观测极光地点。'
			},
			para3: {
				english: 'It is produced when this magnetosphere is disturbed by the solar wind carrying the charged particles. Auroras are seen in latitudes of around 70 degrees. They generally occur in a band known as ‘auroral zone’. The auroral zone is 3 to 6 degrees wide in latitude. It lies between 10 and 20 degrees from the geomagnetic poles.',
				chinese: '通常极光出现时，是呈现弥漫性的发光或"窗帘"，大致向东西方向扩展。有些时候，它们形成"静态弧"，其它的"活跃极光"则会不停的变化，不断的改变形态。每一个帘幕由许多平行的光线组成，每一条光线都内衬著当地的磁场线，暗示极光的形状受到地球磁场的约制。事实上，卫星显示电子循着磁场线，朝向地球方向螺旋著移动。'
			},
			para4: {
				english: 'This is visible quite clearly during the night. Auroras can sometimes be seen at latitudes below the actual auroral zone. Auroras can appear in various forms like streamers, patches, arcs, scattered light, diffused light etc. The brightest and the most distinctive of all forms of auroras are the ones which are curtain-like in the shape of an arc, extending in the east-west direction. This natural light effect is known as ‘aurora borealis’ in northern altitudes.',
				chinese: '与窗帘相似，但皱折更为强烈的被称为"条状（striations）"；当磁场线导引明亮的极光在观赏著的上方绽放，则可能呈现"冕状"或发散的辐射状，这是透视所造成的效果。'
			},
			bookNowButtonDataSrc: {
				english: 'https://widgets.bokun.io/online-sales/14c1969c-2c42-4141-bd76-863d8ca988d9/experience-calendar/181375?partialView=1',
				chinese: 'https://widgets.bokun.io/online-sales/9a1901bb-b1dd-41e2-9f8e-2403ae84e814/experience-calendar/181375?partialView=1'
			},
			bookNowButtonId: {
				english: 'bokun_fc427ed4_e8b1_4aaa_b5aa_a38ad20cce1e',
				chinese: 'bokun_41e9b5e0_b284_4f13_b456_81ead5f2e437'
			}
		},
		southernIceland: {
			seoTitle: {
				english: '3 Days - Southern Iceland Comfort Minibus Tours',
				chinese: '3天冰岛南部迷你巴士轻松游—蓝冰洞，追寻北极光，黄金圈，冰湖及瀑布'
			},
			seo: {
				english: 'Blue ice cave, aurora hunting, iceberg lagoon & waterfalls, golden circle',
				chinese: '3天冰岛南部迷你巴士轻松游—蓝冰洞，追寻北极光，黄金圈，冰湖及瀑布'
			},
			title: {
				english: '3 Days - Southern Iceland Comfort Minibus Tours',
				chinese: '3天冰岛南部迷你巴士轻松游—蓝冰洞，追寻北极光，黄金圈，冰湖及瀑布'
			},
			subTitle1: {
				english: 'Tour Highlights:',
				chinese: '行程亮点：'
			},
			bullet1: {
				english: 'Exploring Ice Cave by Super Jeep.',
				chinese: '使用超级越野车探索冰洞'
			},
			bullet2: {
				english: 'Aurora Hunting with complimentary photography.',
				chinese: '追寻北极光及免费与极光合影'
			},
			bullet3: {
				english: 'Not a rush tour. Each attraction visiting stop is at least 1 hour.',
				chinese: '行程不赶时间，每个景点逗留至少1小时'
			},
			bullet4: {
				english: 'Good Lunch & Breakfast are provided',
				chinese: '提供丰富美味的午餐及早餐'
			},
			bullet5: {
				english: 'Complimentary Hot Bath at Secret Lagoon',
				chinese: '免费于神秘泻湖泡澡'
			},
			bullet6: {
				english: 'Completing the Golden Circle Tour, consist of a periodically spouting hot spring, Geysir, Gullfoss Waterfall & Thingvellir National Park.',
				chinese: '游览整个黄金圈，包括定时喷出温泉的间歇泉，黄金瀑布和辛客里板块国家公园'
			},
			bullet7: {
				english: 'Visiting Seljalandfoss Waterfall, Skogafoss Waterfal & Reynisfjara Beach which is well known as Black Sand Beach.',
				chinese: '游览水帘洞瀑布，彩虹瀑布及黑沙滩'
			},
			bullet8: {
				english: 'Walking to the edge of glacier at Myrdalsjokull.',
				chinese: '步行到米尔达斯冰川边缘'
			},
			bullet9: {
				english: 'Visiting Jokulsarlon Iceberg Lagoon and Diamond Beach.',
				chinese: '游览杰古沙龙冰河湖及钻石沙滩'
			},
			bullet10: {
				english: 'Pick up from hotel door, even in downtown. Punctual is assured.',
				chinese: '酒店接客服务（包括市区酒店）以确保准时'
			},
			bullet11: {
				english: 'Small group tour by minibus. More comfort and leisure is assured.',
				chinese: '使用迷你巴士的小团，肯定更轻松和更舒适'
			},
			subTitle2: {
				english: 'Pick Up Information:',
				chinese: '酒店接客须知:'
			},
			para1: {
				english: 'Reykjanesbaer (Njarvik and Absbru and Keflavik) 7.45am - 8am',
				chinese: 'Reykjanesbaer地区 (Njarvik地区，Absbru地区及凯夫拉维克Keflavik) 7.45am - 8am'
			},
			para2: {
				english: 'Reykjavik hotels 8.30 to 8.45am (all guests pick up from Reykjavik hotels will be sent to the main meeting point)',
				chinese: '雷克雅维克酒店Reykjavik hotels 8.30 至 8.45am (所有客人将从雷克雅维克酒店送往主要集合点)'
			},
			para3: {
				english: 'Reykjavik Main Meeting Point 9am (We depart from here) ',
				chinese: '雷克雅维克主要集合点 9am (我们从这里出发)'
			},
			subTitle3: {
				english: 'Live Guide Languages:',
				chinese: '导游使用的语言:'
			},
			bullet12: {
				english: 'English',
				chinese: '英语'
			},
			bullet13: {
				english: 'Mandarin',
				chinese: '华语'
			},
			bullet14: {
				english: 'Thailand (on request)',
				chinese: '泰语（根据要求）'
			},
			bullet15: {
				english: 'Malay or Indo (on request)',
				chinese: '马来语或印尼语（根据要求）'
			},
			subTitle4: {
				english: 'Descriptions',
				chinese: '行程说明'
			},
			para4: {
				english: 'People visit to Iceland because of Aurora and its natural sceneries, especially ice cave, glaciers, ice bergs, waterfalls, geyser, black sand beach, natural hot bath spa retreat and canyons. In this program, we cover all of these.',
				chinese: '许多人到冰岛旅游是为了北极光及自然风光而来，特别是这里的冰洞、冰川、冰山、瀑布、间歇泉、黑沙滩、天然温泉浴场及峡谷等。因此，我们设计的行程都涵盖了这些所有。'
			},
			para5: {
				english: 'In the night time, our guide sure will always notify you with possibility of northern light sighting. There will be one night, we will bring you out to hunt for the northern light. One of the highlight of this tour is we will take photos for you with Aurora.',
				chinese: '在夜间，我们专业的导游会通知您有关北极光显现的机率，同时在其中一个晚上，我们将带领您去追寻北极光，而我们更特别免费为您与北极光合影。'
			},
			para6: {
				english: 'We will visit to few famous waterfall such as Seljalandsfoss, Skogafoss & Gulfoss.',
				chinese: '此外，我们将带您游览著名的瀑布，如水帘洞瀑布、彩虹瀑布及黄金瀑布。'
			},
			para7: {
				english: 'Seljalandsfoss waterfall drops 60 m (197 ft) and is part of the Seljalands River that has its origin in the volcano glacier Eyjafjallajökull. The most impressive experience is visitors can walk behind the falls into a small cave.',
				chinese: '水帘洞瀑布（Seljalandsfoss） 垂直高度约60米（197尺），起源于火山冰川Eyjafjallajökull，是Seljalands河的一部分。顺着步道走进瀑布后面会让游客留下非常深刻的印象。'
			},
			para8: {
				english: 'Skogafoss is always famous with its rainbow. Due to the amount of spray the waterfall consistently produces, a single or double rainbow is normally visible on sunny days. According to legend, the first Viking settler in the area, Þrasi Þórólfsson, buried a treasure in a cave behind the waterfall. The Skógafoss is one of the biggest waterfalls in the country with a width of 15 metres (49 feet) and a drop of 60 m (200 ft). Gullfoss Waterfall is located in the canyon of Hvítá river in southwest Iceland. It is iconic waterfall in Iceland.',
				chinese: '彩虹瀑布（Skogafoss）以其常显现的彩虹而闻名。由于瀑布持续产生大量的水雾，在阳光明媚的日子通常都可以见到单或双彩虹。据传说，该区的第一个维京人定居者Þrasi Þórólfsson,将一批宝藏埋在瀑布后面的洞穴中。彩虹瀑布是冰岛最大的瀑布之一，宽15米（49尺），垂直高度60米（200尺）。'
			},
			para9: {
				english: 'Hvita river flows in rush and plunges in two stages. The average amount of water running down the waterfall is 140 cubic metres (4,900 cu ft) per second in the summer and 80 cubic metres (2,800 cu ft) per second in the winter.',
				chinese: '黄金瀑布（Gullfoss）位于冰岛西南部Hvítá河的峡谷中，是冰岛其中一个标志性的瀑布。Hvita湍急的河水流经两个断层后注入峡谷，夏季的流水量平均为每秒140立方米（4900立方尺），冬季平均为每秒80立方米（2800立方尺）。'
			},
			para10: {
				english: 'Observing it from the upper level view point, it appears that the river simply vanishes into the earth. Walking to the edge of the waterfall, you can feel its power. On a sunny day shimmering rainbow can be seen over the falls.',
				chinese: '从高处看黄金瀑布，由于流经峡谷，奔流的河水好像突然消失在地面上，沿着瀑布的边缘走，就可感受到其澎拜的力量。在阳光灿烂的日子，瀑布上还可以见到美丽的彩虹。'
			},
			para11: {
				english: 'Majority of glaciers of Iceland locate in Southern Iceland. Therefore in this program, we can approach glaciers in different easy ways. We can do some hiking to the edge of glacier of Myrdalsjokull; appraising ice bergs within Jokulsarlon Galcier Lagoon; and taking photos with debris of glaciers which are stranded at The Diamond Beach. In addition, we will bring you to explore blue ice cave by super jeep as well.',
				chinese: '冰岛大部分冰川都位于冰岛南部。所以我们特别安排不同的方式来体验冰川。我们将徒步到米尔达斯冰川Myrdalsjokull边缘; 欣赏杰古沙龙冰河湖Jokulsarlon Galcier Lagoon的冰山;及在钻石沙滩上与搁浅的闪烁冰川碎片拍照。另外，我们也将使用超级越野车探索蓝冰洞。'
			},
			para12: {
				english: 'Besides, we will bring you to a very unique beach, where you can find fully black sand. It is Reynisfjara. There are some basalt columns too. You may get yourself on that, and having awesome photos for memories. During the trip, you will also discover an amazing periodically spouting hot spring, geyser. The English word geyser (a periodically spouting hot spring) derives from this Geysir which is in our program. Geysir lies in the Haukadalur valley on the slopes of Laugarfjall hill, which is also the home to Strokkur geyser about 50 metres south. Though Geysir has been mostly dormant for many years, Strokkur continues to erupt every 5–10 minutes, and can reach to heights of up to 30 metres. Many people mislabel Strokkur as Geysir.',
				chinese: '此外，我们将带您到一个全黑色沙的独特沙滩。这就是有很多玄武岩柱的Reynisfjara黑沙滩。您可以在这里拍摄许多值得回忆的精彩照片。'
			},
			para13: {
				english: 'Of course will visit Thingvellir National Park too. We will spend almost one and half hour at here. This is a very important visit to you for getting more understanding about Iceland.',
				chinese: '在行程中，您还会探索到令人赞叹、会定时喷出的温泉—大间歇泉。英文单词“geyser” (定时喷出的温泉) 就是源自于这个间歇泉。大间歇泉位于Laugarfjall山坡上的Haukadalur谷，也就是在Strokkur间歇泉约50米之处。虽然大间歇泉多年来一直处于休眠状态，但一旁的Strokkur间歇泉每隔5至10分钟都会喷发一次，最高可达30米。许多人都误认Strokkur间歇泉为大间歇泉。'
			},
			subTitle5: {
				english: 'Þingvellir is a place with strong historical, cultural and geological significance. These are the importants facts of Thingvellir:',
				chinese: '当然，行程也会安排游览辛客里板块国家公园Thingvellir National Park。这个约1个半小时的逗留将让您对冰岛有深一层的了解。'
			},
			bullet16: {
				english: 'The park is located in a rift valley on the Mid-Atlantic Ridge. It is also located on the border between the North American plate and the Eurasian plate.',
				chinese: '这个公园位于大西洋中脊的裂谷中，也是北美板块和欧亚板块之间的边界；'
			},
			bullet17: {
				english: 'At the south of Thingvellir, Thingvallavatn Lake is Iceland\'s largest natural lake.',
				chinese: '在辛客里板块国家公园的南部，就是冰岛最大的天然湖泊 Thingvallavatn湖的所在地；'
			},
			bullet18: {
				english: 'Thingvellir is the oldest parliament in the world, and Iceland’s first parliament, which was established here in 930.',
				chinese: '辛客里板块国家公园是世界公元930成立的最古老议会会址的所在地，同时也是冰岛第一个议会点；'
			},
			bullet19: {
				english: 'It is Iceland\'s first national park.',
				chinese: '辛客里板块国家公园是冰岛第一座国家公园；'
			},
			bullet20: {
				english: 'In 1944, Icelanders announced their independence at here.',
				chinese: '冰岛人于1944年在这里宣布独立；'
			},
			bullet21: {
				english: 'It was designated as a World Heritage Site in 2004.',
				chinese: '辛客里板块国家公园于2004年被列为世界文化遗产。'
			},
			para14: {
				english: 'Our guide will not only put you in the visitor center. We will take you walking through the corridor of the boundary between the North Americantectonic plate and the Eurasian. Then we will explore and visit Oxararfoss Waterfall, Drekkingarhylur Pool, Logberg Law Rock and Thingvellir Church.',
				chinese: '我们的行程不是仅游览游客中心，反之将带您穿过北美洲板块和欧亚大陆板块之间的边界走廊，然后参观Oxararfoss瀑布、Drekkingarhylur池、Logberg法律石及Thingvellir教堂。'
			},
			para15: {
				english: 'Along the journey, you may also enjoy most of scenic and unique sceneries along the south coast of Iceland. There are a lot of canyons along the journey. Besides we also drive through a huge area covered by black sand, which is called The Black Desert. Those mosses field at Kirkjubæjarklaustur can always impress people. There are also plenty of horses and sweeps always smile to you on the green farm. Catching golden sunset with North Atlantic Ocean view makes your trip to be more than wonderful.',
				chinese: '沿途中，您还可以欣赏冰岛南部沿海大部分的风景和独特的景观。其中可见到许多壮丽的峡谷，还有穿过黑沙覆盖大片区域的黑沙漠，而Kirkjubæjarklaustur的苔藓原肯定会让您留下深刻的印象。 此外，感受身躯可爱的冰岛马和大批绵羊在绿油油的农场对您微笑及捕捉北大西洋海岸线的金色夕阳，将让您的旅程更丰富精彩。'
			},
			para16: {
				english: 'Last but not least, we have natural hot bath spa retreat for you after long journey. We have kept a place reserved for you at the natural and unique Secret Lagoon, so that you can get the true Icelandic feeling of lesisue. The pool’s natural surroundings and steam rising into the air gives the place a magical feeling. The warm water stays at 38-40 Celsius all year.During winter, the northern lights often give a great lightshow above Secret Lagoon. What better way to view the spec.',
				chinese: '在长途跋涉的行程结束前，我们将安排您到泻湖泡温泉。我们为您找到一个天然又独特的秘密泻湖，让您泡出真正的冰岛人感觉。泻湖周围常弥漫着轻烟袅袅，给人一种神秘的感觉。温泉水常年水温保持在38至40摄氏度。在冬季， 北极光常在这秘密泻湖的上空上演飞舞秀，有什么会比泡在温泉里欣赏北极光的飞舞秀来得更享受呢？'
			},
			bookNowButtonDataSrc: {
				english: 'https://widgets.bokun.io/online-sales/14c1969c-2c42-4141-bd76-863d8ca988d9/experience-calendar/177600?partialView=1',
				chinese: 'https://widgets.bokun.io/online-sales/9a1901bb-b1dd-41e2-9f8e-2403ae84e814/experience-calendar/177600?partialView=1'
			},
			bookNowButtonId: {
				english: 'bokun_4e3c5bf8_fc7a_4b46_8a08_1da75cfb15f7',
				chinese: 'bokun_bbef2f6d_5af7_4c95_8757_2f3e91d96555'
			}
		},
		fourDaysWestToNorth: {
			seo: {
				english: '4 Days – From West To North of Iceland.',
				chinese: '4天冰岛西部到北部迷你巴士轻松游—火山口，追寻北极光，斯奈菲尔半岛，米湖及各个冰岛必到之景点'
			},
			seoTitle: {
				english: '4 Days – From West To North of Iceland ',
				chinese: '4天冰岛西部到北部迷你巴士轻松游—火山口，追寻北极光，斯奈菲尔半岛，米湖及各个冰岛必到之景点'
			},
			title: {
				english: '4 Days – From West To North of Iceland ',
				chinese: '4天冰岛西部到北部迷你巴士轻松游—火山口，追寻北极光，斯奈菲尔半岛，米湖及各个冰岛必到之景点'
			},
			subTitle1: {
				english: 'Tour Highlights:',
				chinese: ' 行程亮点：'
			},
			bullet1: {
				english: 'Exploring Snaefellsness at the west of Iceland ',
				chinese: '探索冰岛西部的斯奈菲尔国家公园'
			},
			bullet2: {
				english: 'Northern Iceland discovery ',
				chinese: '发现冰岛北部'
			},
			bullet3: {
				english: 'Multiple approach to volcanos ',
				chinese: '多方位欣赏火山'
			},
			bullet4: {
				english: 'Experiencing the highway that near to the sky ',
				chinese: '体验极靠近天际的高速公路行驶'
			},
			bullet5: {
				english: 'North is always the best place to view Northern Lights  ',
				chinese: '冰岛北部一般是最好观赏北极光的地点'
			},
			bullet6: {
				english: 'Not a rush tour. Each attraction visiting stop is at least 1 hour.',
				chinese: '行程不赶时间，每个景点逗留至少1小时'
			},
			bullet7: {
				english: 'Good Lunch & Breakfast are provided ',
				chinese: '提供丰富美味的午餐及早餐'
			},
			bullet8: {
				english: 'Pick up from hotel door, even in downtown. Punctual is assured. ',
				chinese: '酒店接客服务（包括市区酒店）以确保准时'
			},
			bullet9: {
				english: 'Small group tour by minibus. More comfort and leisure is assured.  ',
				chinese: '使用迷你巴士的小团，肯定更轻松和更舒适'
			},
			subTitle2: {
				english: 'Pick Up Information: ',
				chinese: '酒店接客须知:'
			},
			para1: {
				english: 'Reykjanesbaer (Njarvik and Absbru and Keflavik) 7.45am - 8am ',
				chinese: 'Reykjanesbaer地区 (Njarvik地区，Absbru地区及凯夫拉维克Keflavik) 7.45am - 8am'
			},
			para2: {
				english: 'Reykjavik hotels 8.30 to 8.45am (all guests pick up from Reykjavik hotels will be sent to the main meeting point)',
				chinese: '雷克雅维克酒店Reykjavik hotels 8.30 至 8.45am (所有客人将从雷克雅维克酒店送往主要集合点)'
			},
			para3: {
				english: 'Reykjavik Main Meeting Point 9am (We depart from here)  ',
				chinese: '雷克雅维克主要集合点 9am (我们从这里出发)'
			},
			subTitle3: {
				english: 'Live Guide Languages: ',
				chinese: '导游使用的语言:'
			},
			bullet10: {
				english: 'English',
				chinese: '英语'
			},
			bullet11: {
				english: 'Mandarin',
				chinese: '华语'
			},
			bullet12: {
				english: 'Thailand (on request) ',
				chinese: '泰语（根据要求）'
			},
			bullet13: {
				english: 'Malay or Indo (on request) ',
				chinese: '马来语或印尼语（根据要求）'
			},
			subTitle4: {
				english: 'Descriptions',
				chinese: '行程说明'
			},
			para4: {
				english: 'Arnastapi, there the waves of the ocean play along with the sun and the daylight to produce a natural show of which the most spectacular can be experienced at the cliff Gatklettur, and the rifts Hundagjá, Miðgjá and Músagjá. Near Arnarstapi you will also find many other interesting natural wonders like Rauðfeldsgjá, Dritvík, Bjarnarfoss and Lóndrangar. There is great birdlife around the cliffs and pretty flora surrounding the area.',
				chinese: 'Arnastapi, 这里的海洋浪花和阳光互相融合，产生了一个自然的化学作用，在西部拱门（Gatklettur）及Hundagjá、Miðgjá 和Músagjá裂缝间呈现一场最壮观的演出。在 Arnarstapi附近，您还可以看到许多其他有趣的自然奇观，如Rauðfeldsgjá、Dritvík、 儿童瀑布及魔王岩石等。悬崖周围有很多特殊的鸟类，周边还围绕着许多美丽的植物。'
			},
			para5: {
				english: 'Gatklettur - Arch Rock is a cliff with a circular arch. Rock Arch shows how distinctive wave action has eroded the rocks into arches and beautiful swirled patterns. The Lóndrangar are a pair of rock pinnacles in Iceland. They are volcanic plugs of basalt, that have been hewn out from softer surrounding rock by erosion.',
				chinese: '西部拱门（Gatklettur） - 西部拱门是一个圆拱的悬崖。这里的岩石多年来经磅礴的海浪侵蚀，形成一道天然拱门及美丽的漩涡图案。魔王岩石（Lóndrangar）则是一对岩石尖峰，属火山熔岩的玄武岩，较松软的表层经自然侵蚀后，尖峰随着大自然的鬼斧神工凿出来。'
			},
			para6: {
				english: 'Kirkjufell (Icelandic: Church mountain) is a 463 m[1] high mountain on the north coast of Iceland\'s Snæfellsnespeninsula, near the town of Grundarfjörður. Kirkjufell was one of the filming locations for Game of Thrones season 6 and 7, featuring as the "arrowhead mountain" that the Hound and the company north of the Wall see when capturing a wight. (credited to Wikipedia)',
				chinese: '教会山Kirkjufell (冰岛文: Church mountain) 是一座463米高，靠近Grundarfjörður镇，位于冰岛斯奈菲尔半岛北海岸的山丘。这里也是电视剧“权力游戏”第6季及第7季的拍摄地点之一，其“箭头山”形的特色极为醒目。'
			},
			para7: {
				english: 'Kirkjufellsfoss is a very nice waterfall next to the mountain.',
				chinese: '教会山瀑布（Kirkjufellsfoss）是教会山旁的一座瀑布，与教会山相映成精彩画面。'
			},
			para8: {
				english: 'Godafoss is located in the Bárðardalur district of Northeastern Region at the beginning of the Sprengisandur highland road. This pristine \'Waterfall of the Gods\' is one of the most wonder and impressive attraction in Iceland. The source of water is mainly from glacier. The emerald blue water flows over an elegant semi-circular arch and falls into a river surrounded by lava rocks. It always causes blue-green swirling patterns in the water below among the surrounding lava.',
				chinese: '神之瀑布（Godafoss） 位于冰岛东北区的Bárðardalur区，处于Sprengisandur高原公路的起点。这个原始的“众神瀑布”是冰岛最奇妙和最让人印象深刻的景点之一。水源主要来自冰川。翡翠蓝色的水流过优雅的半圆形拱门，落入被熔岩岩石包围的河流中。水流在熔岩阵中产生非常漂亮的蓝绿色旋涡图案。'
			},
			para9: {
				english: 'Myvatn Lake, the lake was created by a large basaltic lava eruption 2300 years ago, and the surrounding landscape is dominated by volcanic landforms, including lava pillars and rootless vents. The lake and its surrounding wetlands have an exceptionally rich fauna of waterbirds, especially ducks.',
				chinese: ' 米湖（Myvatn Lake）, 这个湖是由2300年前的大型玄武岩熔岩喷发而形成的，周围的景观以火山地貌为主，包括熔岩柱和无根通风口。湖泊及周围的湿地拥有极为丰富如野鸭子的水鸟动物群。火山口蓝湖（blue lake）位于 米湖旁。在这里即使稍作停留，其美景也将为你留下终生难忘的回忆。'
			},
			para10: {
				english: 'There is a blue lake next to Myvatn. The Blue Lake is so impressive. Short stop at here but will leave you a lifelong memory. ',
				chinese: '在Namafjall火山的山脚下，有一个名为Hveraröndor Hverir的温泉。Namafjall-Hverir地热区看起来像火星表面。有许多喷气孔、泥浆地、泥盆和蒸汽喷口，似乎都不断沸腾并产生无穷无尽的蒸汽。这里的巨大泥陨石坑的面积也会让你惊叹不已。'
			},
			para11: {
				english: 'At the foothills of Namafjall volcanic mountain, is an expanse of hot springs called Hveraröndor Hverir. Namafjall-Hverir geothermal area looks like the surface of Mars. There are a number of fumaroles, mud pools, mud pots and steam vents that all seem to be continuously boiling and producing endless steam. The gigantic size of the mud craters is what makes you go \'wow\' at the sight of them.',
				chinese: 'Krafla Viti火山口是一个灌满蓝色水的圆形火山口，周围 环绕着地热区和岩石火山。'
			},
			para12: {
				english: 'The Krafla Viti crater is a circular crater filled with blue water and surrounded by a geothermal area and rocky volcanic mountains. ',
				chinese: '黛提瀑布（Dettifoss），是欧洲最强大的瀑布。其流量是欧洲瀑布之冠，每秒达500立方米的水流量。停车场距离黛提瀑布有1公里。途中，您可能会发现另一个名为塞尔福斯瀑布。位于Jökulsárgljúfur峡谷，宽度比黛提瀑布还宽。'
			},
			para13: {
				english: 'Dettifoss, the latter Europe\'s mightiest waterfall. It is with the greatest volume of any waterfall in Europe, 500 cubic metres of water per second plunges over the edge. The distance between car park and Dettisoss is 1 km. En-route, you may find another waterfall, named Selfoss. It’s located in Jökulsárgljúfur canyon with a wider width than Dettifoss.',
				chinese: '熔岩瀑布及儿童瀑布Hraunfossar and Barnafoss Waterfalls'
			},
			para14: {
				english: 'Hraunfossar and Barnafoss Waterfalls. Barnafoss is on the river Hvítá in Borgarfjörður. Whereas Hraunfossar flows out of a lava field into Hvítá near Barnafoss, creating a stunning scenery. Hvitá River, is downstream from Gullfoss waterfall.  Hraunfossar is a long wall of water cascading over moss covered lava, falling into bright turquoise water! Barnafoss means “children´s waterfall” and has a creepy Icelandic legend that goes along with it. Barnafoss is near Hraunfossar which burst out of Hallmundarhraun which is a great lava plain.',
				chinese: '儿童瀑布位于Borgarfjörður的Hvítá河畔。熔岩瀑布的水则是从熔岩壁的缝间渗出，流入儿童瀑布附近的Hvítá河，营造出一道迷人的风景。Hvitá河其实就是冰岛著名的黄金瀑布之下游。熔岩瀑布看似一道水墙，笼罩在苔藓覆盖的熔岩上，瀑布水沿着熔岩落入布满明亮的绿松石的水中。Barnafoss在冰岛语意为 “儿童瀑布”，这里有过令人心酸的冰岛传说。儿童瀑布与熔岩瀑布毗邻，是当年Hallmundarhraun火山爆发后形成的独特熔岩平原。'
			},
			para15: {
				english: 'Akureyri City is a beautiful town nestled between mountains and the deep blue sea. First, you must grab the ocean view from the bottom of the Eyjafjörður fjord is an impressive sight, balanced by the town panorama bearing the church as a crown on top of its steepest hill. Besides, the town offers a wide range of activities and interesting places. Especially, the  “Old Town” of Akureyri is the home to many of the museums and historic buildings. Return back to the town centre along the new walking path at the seaside or Pollurinn as the locals call it and enjoy the views over the fjord on the way. Here is really large enough for you to explore.',
				chinese: '阿克雷里市（Akureyri City）是一座美丽的小镇，坐落在群山和深蓝色的大海之间。首先， 您可以从Eyjafjörður峡湾底部遥望壮丽的海景，与城镇全景相映取得平衡协调感，而处于最陡峭的山顶教堂，俨如一座皇冠，无疑增添了这个地方的气质。此外， 该镇也拥有许多景点及旅游活动，如阿克雷里的老城区，正是许多博物馆和历史建筑的所在地。沿着海边或Pollurinn 的新步道返回市中心，还可以欣赏到峡湾的壮丽美景。阿克雷里，绝对值得您一一去探索。'
			},
			para16: {
				english: 'In the Christmas garden you\'ll find the magical world of Christmas. On the outside the fire-engine red house resembles a cookie house but once inside you discover Christmas decorations from all over the world, alongside with traditional Icelandic Christmas items and variations of selected handicrafts from local craftsmen/women.',
				chinese: '在圣诞花园（Christmas garden），您会找到传说中神奇的圣诞世界。消防车红的房子外观就像一个饼干屋。一旦进入，您肯定会被来自世界各地的圣诞装饰品，以及冰岛传统圣诞装饰品和当地工匠/女性的精选手工艺品所吸引。'
			},
			para17: {
				english: 'Grábrók is a crater formed by a fissure eruption approximately three thousand years ago. Nowadays Grabrok  serve you ultimate 360 degree scenic view. There is not only lava view. It is also an impressive view over the most beautiful part of the Western Region Borgarfjörður and the tiny but beautiful lake	Hreðarvatn, that was formed during that eruption.  To the northwest is a view to Baula a cone shaped and colorful mountain. To the northwest is a view to Baula a cone shaped and colorful mountain.',
				chinese: '格拉布鲁克火山口（Grábrók）是一个约三千年前裂缝喷发形成的火山口，这里可提供360度的视角风景。格拉布鲁克火山口不仅有熔岩景观，同时也是冰岛西部地区Borgarfjörður最美丽的部分。火山喷发期间形成精致的Hreðarvatn湖也另许多游客留下深刻的印象。在西北方向，还可以看到著名锥型多彩的Baula山峰。'
			},
			para18: {
				english: 'Londrangar is Uniquely-formed remnants of ancient basalt volcanic dikes sticking out from the sea. The higher pillar (75m) was first climbed in 1735 and the lower (61 m) in 1938. Below the hill you may find Thufubjarg cliff where according to a folktale the poet Kolbeinn Joklaskald had an encounter with the Devil. On these cliffs, puffins and fulmars have their nests.',
				chinese: '魔王岩石（Londrangar） 是一个向海中伸展出的古老玄武岩火山堤经侵蚀后形成的尖峰。根据记录，较高的尖峰（75米）在1735年首次被人登顶，而较低的尖峰（61米）则在1938年首次被人登顶。在山下，您可以欣赏到Thufubjarg 悬崖，据说，诗人Kolbeinn Joklaskald 曾在此与魔鬼相遇。此外，这些悬崖也是海鹦及许多海鸟的栖息地。'
			},
			bookNowButtonDataSrc: {
				english: 'https://widgets.bokun.io/online-sales/14c1969c-2c42-4141-bd76-863d8ca988d9/experience-calendar/175815?partialView=1',
				chinese: 'https://widgets.bokun.io/online-sales/9a1901bb-b1dd-41e2-9f8e-2403ae84e814/experience-calendar/175815?partialView=1'
			},
			bookNowButtonId: {
				english: 'bokun_9228737e_a09f_4d14_a530_cf8d120d14ad',
				chinese: 'bokun_7457c557_3996_4a9f_a321_d4a87563a5a6'
			}
		},
		fourDaysSouthernIceland: {
			seo: {
				english: 'Blue ice cave, aurora hunting, golden circle, iceberg lagoon & waterfalls.',
				chinese: '4天冰岛南部迷你巴士轻松游—蓝冰洞，追寻北极光，黄金圈，冰湖及瀑布'
			},
			title: {
				english: '4 Days - Southern Iceland Comfort Minibus Tours',
				chinese: '4天冰岛南部迷你巴士轻松游—蓝冰洞，追寻北极光，黄金圈，冰湖及瀑布'
			},
			subTitle1: {
				english: 'Tour Highlights:',
				chinese: '行程亮点：'
			},
			bullet1: {
				english: 'Exploring Ice Cave by Super Jeep.',
				chinese: '使用超级越野车探索冰洞'
			},
			bullet2: {
				english: 'Aurora Hunting with complimentary photography.',
				chinese: '追寻北极光及免费与极光合影'
			},
			bullet3: {
				english: 'Not a rush tour. Each attraction visiting stop is at least 1 hour.',
				chinese: '行程不赶时间，每个景点逗留至少1小时'
			},
			bullet4: {
				english: 'Good Lunch & Breakfast are provided',
				chinese: '提供丰富美味的午餐及早餐'
			},
			bullet5: {
				english: 'Complimentary Hot Bath at Secret Lagoon',
				chinese: '免费于神秘泻湖泡澡'
			},
			bullet6: {
				english: 'Completing the Golden Circle Tour, consist of a periodically spouting hot spring, Geysir, Gullfoss Waterfall & Thingvellir National Park.',
				chinese: '游览整个黄金圈，包括定时喷出温泉的间歇泉，黄金瀑布和辛客里板块国家公园'
			},
			bullet7: {
				english: 'Visiting Seljalandfoss Waterfall, Skogafoss Waterfal & Reynisfjara Beach which is well known as Black Sand Beach.',
				chinese: '游览水帘洞瀑布，彩虹瀑布及黑沙滩'
			},
			bullet8: {
				english: 'Walking to the edge of glacier at Myrdalsjokull.',
				chinese: '步行到米尔达斯冰川边缘'
			},
			bullet9: {
				english: 'Visiting Jokulsarlon Iceberg Lagoon and Diamond Beach.',
				chinese: '游览杰古沙龙冰河湖及钻石沙滩'
			},
			bullet10: {
				english: 'Pick up from hotel door, even in downtown. Punctual is assured.',
				chinese: '酒店接客服务（包括市区酒店）以确保准时'
			},
			bullet11: {
				english: 'Small group tour by minibus. More comfort and leisure is assured.',
				chinese: '使用迷你巴士的小团，肯定更轻松和更舒适'
			},
			subTitle2: {
				english: 'Pick Up Information:',
				chinese: '酒店接客须知:'
			},
			para1: {
				english: 'Reykjanesbaer (Njarvik and Absbru and Keflavik) 7.45am - 8am',
				chinese: 'Reykjanesbaer地区 (Njarvik地区，Absbru地区及凯夫拉维克Keflavik) 7.45am - 8am'
			},
			para2: {
				english: 'Reykjavik hotels 8.30 to 8.45am (all guests pick up from Reykjavik hotels will be sent to the main meeting point)',
				chinese: '雷克雅维克酒店Reykjavik hotels 8.30 至 8.45am (所有客人将从雷克雅维克酒店送往主要集合点)'
			},
			para3: {
				english: 'Reykjavik Main Meeting Point 9am (We depart from here) ',
				chinese: '雷克雅维克主要集合点 9am (我们从这里出发)'
			},
			subTitle3: {
				english: 'Live Guide Languages:',
				chinese: '导游使用的语言:'
			},
			bullet12: {
				english: 'English',
				chinese: '英语'
			},
			bullet13: {
				english: 'Mandarin',
				chinese: '华语'
			},
			bullet14: {
				english: 'Thailand (on request)',
				chinese: '泰语（根据要求）'
			},
			bullet15: {
				english: 'Malay or Indo (on request)',
				chinese: '马来语或印尼语（根据要求）'
			},
			subTitle4: {
				english: 'Descriptions',
				chinese: '行程说明'
			},
			bullet16: {
				english: 'People visit to Iceland because of Aurora and its natural sceneries, especially ice cave, glaciers, ice bergs, waterfalls, geyser, black sand beach, natural hot bath spa retreat and canyons. In this program, we cover all of these .',
				chinese: '许多人到冰岛旅游是为了北极光及自然风光而来，特别是这里的冰洞、冰川、冰山、瀑布、间歇泉、黑沙滩、天然温泉浴场及峡谷等。因此，我们设计的行程都涵盖了这些所有。'
			},
			bullet17: {
				english: 'In the night time, our guide sure will always notify you with possibility of northern light sighting. There will be one night, we will bring you out to hunt for the northern light. One of the highlight of this tour is we will take photos for you with Aurora.',
				chinese: '在夜间，我们专业的导游会通知您有关北极光显现的机率，同时在其中一个晚上，我们将带领您去追寻北极光，而我们更特别免费为您与北极光合影。'
			},
			bullet18: {
				english: 'We will visit to few famous waterfall such as Seljalandsfoss, Skogafoss & Gulfoss.',
				chinese: '此外，我们将带您游览著名的瀑布，如水帘洞瀑布、彩虹瀑布及黄金瀑布。'
			},
			bullet19: {
				english: 'Seljalandsfoss waterfall drops 60 m (197 ft) and is part of the Seljalands River that has its origin in the volcano glacier Eyjafjallajökull. The most impressive experience is visitors can walk behind the falls into a small cave.',
				chinese: '水帘洞瀑布（Seljalandsfoss） 垂直高度约60米（197尺），起源于火山冰川Eyjafjallajökull，是Seljalands河的一部分。顺着步道走进瀑布后面会让游客留下非常深刻的印象。'
			},
			bullet20: {
				english: 'Skogafoss is always famous with its rainbow. Due to the amount of spray the waterfall consistently produces, a single or double rainbow is normally visible on sunny days. According to legend, the first Viking settler in the area, Þrasi Þórólfsson, buried a treasure in a cave behind the waterfall. The Skógafoss is one of the biggest waterfalls in the country with a width of 15 metres (49 feet) and a drop of 60 m (200 ft). Gullfoss Waterfall is located in the canyon of Hvítá river in southwest Iceland. It is iconic waterfall in Iceland.',
				chinese: '彩虹瀑布（Skogafoss）以其常显现的彩虹而闻名。由于瀑布持续产生大量的水雾，在阳光明媚的日子通常都可以见到单或双彩虹。据传说，该区的第一个维京人定居者Þrasi Þórólfsson,将一批宝藏埋在瀑布后面的洞穴中。彩虹瀑布是冰岛最大的瀑布之一，宽15米（49尺），垂直高度60米（200尺）。'
			},
			bullet21: {
				english: 'Hvita river flows in rush and plunges in two stages. The average amount of water running down the waterfall is 140 cubic metres (4,900 cu ft) per second in the summer and 80 cubic metres (2,800 cu ft) per second in the winter.',
				chinese: '黄金瀑布（Gullfoss）位于冰岛西南部Hvítá河的峡谷中，是冰岛其中一个标志性的瀑布。Hvita湍急的河水流经两个断层后注入峡谷，夏季的流水量平均为每秒140立方米（4900立方尺），冬季平均为每秒80立方米（2800立方尺）。'
			},
			bullet22: {
				english: 'Observing it from the upper level view point, it appears that the river simply vanishes into the earth. Walking to the edge of the waterfall, you can feel its power. On a sunny day shimmering rainbow can be seen over the falls.',
				chinese: '从高处看黄金瀑布，由于流经峡谷，奔流的河水好像突然消失在地面上，沿着瀑布的边缘走，就可感受到其澎拜的力量。在阳光灿烂的日子，瀑布上还可以见到美丽的彩虹。'
			},
			bullet23: {
				english: 'Majority of glaciers of Iceland locate in Southern Iceland. Therefore in this program, we can approach glaciers in different easy ways. We can do some hiking to the edge of glacier of Myrdalsjokull; appraising ice bergs within Jokulsarlon Galcier Lagoon; and taking photos with debris of glaciers which are stranded at The Diamond Beach. In addition, we will bring you to explore blue ice cave by super jeep as well.',
				chinese: '冰岛大部分冰川都位于冰岛南部。所以我们特别安排不同的方式来体验冰川。我们将徒步到米尔达斯冰川Myrdalsjokull边缘; 欣赏杰古沙龙冰河湖Jokulsarlon Galcier Lagoon的冰山;及在钻石沙滩上与搁浅的闪烁冰川碎片拍照。另外，我们也将使用超级越野车探索蓝冰洞。'
			},
			bullet24: {
				english: 'Besides, we will bring you to a very unique beach, where you can find fully black sand. It is Reynisfjara. There are some basalt columns too. You may get yourself on that, and having awesome photos for memories. During the trip, you will also discover an amazing periodically spouting hot spring, geyser. The English word geyser (a periodically spouting hot spring) derives from this Geysir which is in our program. Geysir lies in the Haukadalur valley on the slopes of Laugarfjall hill, which is also the home to Strokkur geyser about 50 metres south. Though Geysir has been mostly dormant for many years, Strokkur continues to erupt every 5–10 minutes, and can reach to heights of up to 30 metres. Many people mislabel Strokkur as Geysir.',
				chinese: '此外，我们将带您到一个全黑色沙的独特沙滩。这就是有很多玄武岩柱的Reynisfjara黑沙滩。您可以在这里拍摄许多值得回忆的精彩照片。'
			},
			bullet25: {
				english: 'Of course will visit Thingvellir National Park too. We will spend almost one and half hour at here. This is a very important visit to you for getting more understanding about Iceland.',
				chinese: '在行程中，您还会探索到令人赞叹、会定时喷出的温泉—大间歇泉。英文单词“geyser” (定时喷出的温泉) 就是源自于这个间歇泉。大间歇泉位于Laugarfjall山坡上的Haukadalur谷，也就是在Strokkur间歇泉约50米之处。虽然大间歇泉多年来一直处于休眠状态，但一旁的Strokkur间歇泉每隔5至10分钟都会喷发一次，最高可达30米。许多人都误认Strokkur间歇泉为大间歇泉。'
			},
			bulletExtra: {
				english: '',
				chinese: '当然，行程也会安排游览辛客里板块国家公园Thingvellir National Park。这个约1个半小时的逗留将让您对冰岛有深一层的了解。'
			},
			subTitle5: {
				english: 'Þingvellir is a place with strong historical, cultural and geological significance. These are the importants facts of Thingvellir:',
				chinese: '辛客里板块国家公园具有浓厚历史、文化和地质的特殊意义地位，计有：'
			},
			bullet26: {
				english: 'The park is located in a rift valley on the Mid-Atlantic Ridge. It is also located on the border between the North American plate and the Eurasian plate.',
				chinese: '这个公园位于大西洋中脊的裂谷中，也是北美板块和欧亚板块之间的边界；'
			},
			bullet27: {
				english: 'At the south of Thingvellir, Thingvallavatn Lake is Iceland\'s largest natural lake.',
				chinese: '在辛客里板块国家公园的南部，就是冰岛最大的天然湖泊 Thingvallavatn湖的所在地；'
			},
			bullet28: {
				english: 'Thingvellir is the oldest parliament in the world, and Iceland’s first parliament, which was established here in 930.',
				chinese: '辛客里板块国家公园是世界公元930成立的最古老议会会址的所在地，同时也是冰岛第一个议会点；'
			},
			bullet29: {
				english: 'It is Iceland\'s first national park.',
				chinese: '辛客里板块国家公园是冰岛第一座国家公园；'
			},
			bullet30: {
				english: 'In 1944, Icelanders announced their independence at here.',
				chinese: '冰岛人于1944年在这里宣布独立；'
			},
			bullet31: {
				english: 'It was designated as a World Heritage Site in 2004.',
				chinese: '辛客里板块国家公园于2004年被列为世界文化遗产。'
			},
			para4: {
				english: 'Our guide will not only put you in the visitor center. We will take you walking through the corridor of the boundary between the North Americantectonic plate and the Eurasian. Then we will explore and visit Oxararfoss Waterfall, Drekkingarhylur Pool, Logberg Law Rock and Thingvellir Church.',
				chinese: '我们的行程不是仅游览游客中心，反之将带您穿过北美洲板块和欧亚大陆板块之间的边界走廊，然后参观Oxararfoss瀑布、Drekkingarhylur池、Logberg法律石及Thingvellir教堂。'
			},
			para5: {
				english: 'Along the journey, you may also enjoy most of scenic and unique sceneries along the south coast of Iceland. There are a lot of canyons along the journey. Besides we also drive through a huge area covered by black sand, which is called The Black Desert. Those mosses field at Kirkjubæjarklaustur can always impress people. There are also plenty of horses and sweeps always smile to you on the green farm. Catching golden sunset with North Atlantic Ocean view makes your trip to be more than wonderful.',
				chinese: '沿途中，您还可以欣赏冰岛南部沿海大部分的风景和独特的景观。其中可见到许多壮丽的峡谷，还有穿过黑沙覆盖大片区域的黑沙漠，而Kirkjubæjarklaustur的苔藓原肯定会让您留下深刻的印象。 此外，感受身躯可爱的冰岛马和大批绵羊在绿油油的农场对您微笑及捕捉北大西洋海岸线的金色夕阳，将让您的旅程更丰富精彩。'
			},
			para6: {
				english: 'Last but not least, we have natural hot bath spa retreat for you after long journey. We have kept a place reserved for you at the natural and unique Secret Lagoon, so that you can get the true Icelandic feeling of lesisue. The pool’s natural surroundings and steam rising into the air gives the place a magical feeling. The warm water stays at 38-40 Celsius all year.During winter, the northern lights often give a great lightshow above Secret Lagoon. What better way to view the spec.',
				chinese: '在长途跋涉的行程结束前，我们将安排您到泻湖泡温泉。我们为您找到一个天然又独特的秘密泻湖，让您泡出真正的冰岛人感觉。泻湖周围常弥漫着轻烟袅袅，给人一种神秘的感觉。温泉水常年水温保持在38至40摄氏度。在冬季， 北极光常在这秘密泻湖的上空上演飞舞秀，有什么会比泡在温泉里欣赏北极光的飞舞秀来得更享受呢？'
			},
			bookNowButtonDataSrc: {
				english: 'https://widgets.bokun.io/online-sales/14c1969c-2c42-4141-bd76-863d8ca988d9/experience-calendar/172346?partialView=1',
				chinese: 'https://widgets.bokun.io/online-sales/9a1901bb-b1dd-41e2-9f8e-2403ae84e814/experience-calendar/172346?partialView=1'
			},
			bookNowButtonId: {
				english: 'bokun_b6665183_ca5c_466d_8e81_be0d4897d3aa',
				chinese: 'bokun_ea7b690d_9e90_43eb_b4bc_cdfa751cf0a8'
			}
		},
		twoDaysPuffinAndGlacier: {
			seo: {
				english: 'PUFFIN AND GLACIER TOUR',
				chinese: '海鹦及冰川体验之旅'
			},
			title: {
				english: '2 DAYS – PUFFIN AND GLACIER TOUR',
				chinese: '2 天 – 海鹦及冰川体验之旅'
			},
			bullet1: {
				english: '2 DAYS – PUFFIN AND GLACIER TOUR',
				chinese: '2 天 – 海鹦及冰川体验之旅'
			},
			bookNowButtonDataSrc: {
				english: 'https://widgets.bokun.io/online-sales/14c1969c-2c42-4141-bd76-863d8ca988d9/experience-calendar/322815?partialView=1',
				chinese: 'https://widgets.bokun.io/online-sales/9a1901bb-b1dd-41e2-9f8e-2403ae84e814/experience-calendar/322815?partialView=1'
			},
			bookNowButtonId: {
				english: 'bokun_dfa99358_8160_4cc6_9759_8164ce171a26',
				chinese: 'bokun_7c25894e_d73a_48cf_9300_9e7ef02cda8d'
			}
		},
		trips: [
			{
				image: '/trips/1.jpeg',
				price: 92000,
				title: {
					english: '4 Days - Southern Iceland Comfort Minibus Tours',
					chinese: '4天冰岛南部迷你巴士轻松游—蓝冰洞，追寻北极光，黄金圈，冰湖及瀑布'
				},
				description: {
					english: 'Blue ice cave, aurora hunting, golden circle, iceberg lagoon & waterfalls',
					chinese: '4天冰岛南部迷你巴士轻松游—蓝冰洞，追寻北极光，黄金圈，冰湖及瀑布'
				},
				link: 'toursFour'
			},
			{
				image: '/trips/2.jpeg',
				price: 119000,
				title: {
					english: '4 Days – From West To North of Iceland',
					chinese: '4天冰岛西部到北部迷你巴士轻松游—火山口，追寻北极光，斯奈菲尔半岛，米湖及各个冰岛必到之景点。'
				},
				description: {
					english: '4 Days – From West To North of Iceland',
					chinese: '4天冰岛西部到北部迷你巴士轻松游—火山口，追寻北极光，斯奈菲尔半岛，米湖及各个冰岛必到之景点。'
				},
				link: 'toursThree'
			},
			{
				image: '/trips/3.jpeg',
				price: 88000,
				title: {
					english: '3 Days - Southern Iceland Comfort Minibus Tours',
					chinese: '3天冰岛南部迷你巴士轻松游—蓝冰洞，追寻北极光，黄金圈，冰湖及瀑布'
				},
				description: {
					english: 'Blue ice cave, aurora hunting, iceberg lagoon & waterfalls, golden circle',
					chinese: '3天冰岛南部迷你巴士轻松游—蓝冰洞，追寻北极光，黄金圈，冰湖及瀑布'
				},
				link: 'toursTwo'
			},
			{
				image: '/trips/4.jpeg',
				price: 8800,
				title: {
					english: 'Aurora Hunting by Mercedes Benz Sprinter (Small group)',
					chinese: '豪华奔驰斯宾特-极光团 '
				},
				description: {
					english: 'Aurora Hunting by Mercedes Benz (small group)',
					chinese: '豪华奔驰斯宾特-极光团 '
				},
				link: 'toursOne'
			},
			{
				image: '/trips/5.jpeg',
				price: 29000,
				title: {
					english: '2 DAYS – PUFFIN AND GLACIER TOUR',
					chinese: '2 天 – 海鹦及冰川体验之旅'
				},
				description: {
					english: 'PUFFIN AND GLACIER TOUR',
					chinese: '海鹦及冰川体验之旅'
				},
				link: 'toursFive'
			}
		]
	},
	mutations: {

	},
	actions: {

	}
});
